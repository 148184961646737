import {reactive, toRefs, onBeforeMount, onMounted, defineComponent, getCurrentInstance, nextTick, ref, watch, computed} from 'vue';
import PortalUtil ,{IPortalDataObj} from "./portalUtil";
import config from "@/utils/config";
import {useRoute} from "vue-router";
export default defineComponent ({
    name: "split",
    props: {},
    setup(props,context){
        //context.attrs将会收到组件v-bind:传进来的参数
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        const route = useRoute()
        let dataObj:IPortalDataObj=reactive<IPortalDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            otherParams:{
                welcomeMsg:sessionStorage.getItem("welcomeMsg") as string,//欢迎语
                hasEditPortal:(JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("userInfo"))) as IStoreUserInfo).hasEditPortal, //用户是否有编辑定制portal的权限
                portalVisible:false,//添加删除portal弹出框控制字段
                colNum:2,//默认每列显示多少个portal
                portalHeight:0,//每个portal的高度，动态计算出来的
                userPortals:[],//当前登陆用户配置好的portals
                portalData:[],//当前登陆用户一共可以配置的portals
                refreshHelper:[],//每个配置好的portal头部有刷新按钮，这个数组是用来帮助实现刷新用的
                flag1:config.flag1
            }
        })

        onBeforeMount(()=>{
            dataObj.utilInst=new PortalUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async ()=>{
                await dataObj.utilInst.initPortalData();
                dataObj.otherParams.portalHeight=dataObj.utilInst.calPortalHeight();//重新计算每个portal的高度
            })
            //监听窗口大小变化，主要是为了重新计算portal的高度
            window.addEventListener('resize', () => {
                dataObj.otherParams.portalHeight=dataObj.utilInst.calPortalHeight();//重新计算每个portal的高度
            });
        })
        //---------------------------computed---------------------------
        //如果colNum==1，那么就会采用portalColumn样式，即列模式，每个子项都铺满整行，一行只显示一个
        //如果colNum！=1，则采用flow的row模式，所有项平分一行
        const portalAreaShowType=computed(()=>{
            return {
                portalRow: dataObj.otherParams.colNum != 1,
                portalColumn: dataObj.otherParams.colNum == 1
            };
        })

        //根据portal显示的列数动态计算每个portal采用的样式
        const portalItem=computed(()=>{
            //在选择一列多少个之后，其实是把每个portal的宽度改为对应的百分比，因此这里用动态样式
            return {
                portalItem1: dataObj.otherParams.colNum == 1,
                portalItem2: dataObj.otherParams.colNum == 2,
                portalItem3: dataObj.otherParams.colNum == 3,
                portalDiv: true
            };
        })

        //构建每个portal路由的key，当key发生变化的时候，该portal的路由就会重新加载，所以portal的路由key为：id+时间
        //刷新某个portal的时候，值改变这个portal对应的时间即可，这样就可以实现刷新指定portal
        const portalKey=computed(()=>{
            return (portalId:string) => {
                return portalId + dataObj.otherParams.refreshHelper[portalId];//portalId+时间，刷新portal的时候，改变refreshHelper对象里面对应portal的时间就可以了
            };
        })

        //在首页里面要么显示portal，要么显示其它的组件，不能一起显示，我们要显示portal的路由为/welcome
        const isShowPortal=computed(()=>{
            return route.path == "/welcome" && dataObj.otherParams.hasEditPortal && dataObj.otherParams.flag1;
        })
        return{
            ...toRefs(dataObj),route,portalAreaShowType,portalItem,portalKey,isShowPortal
        }
    },
    components: {}
});